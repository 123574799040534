<div class="p-md-4 p-4 card-bg rounded fs-14">
    <div class="d-flex justify-content-center">
      <ul class="nav nav-pills nav-pills-white-bg mb-3" id="pills-tab" role="tablist">
        <li class="nav-item" role="presentation">
          <button class="nav-link active" id="pills-personal-details-tab" data-bs-toggle="pill" data-bs-target="#pills-personal-details" type="button" role="tab">Personal Details</button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="pills-other-info-tab" data-bs-toggle="pill" data-bs-target="#pills-other-info" type="button" role="tab">Other information</button>
        </li>
      </ul>
    </div>
    <div class="tab-content" id="pills-tabContent">
      <div class="tab-pane fade show active" id="pills-personal-details" role="tabpanel" tabindex="0">
        <div class="bg-input px-3 py-4 rounded">
          <h5 class="fs-18 text-link mb-3">Personal Details</h5>
          <ul class="list-unstyled row gy-3 gx-3 fw-15 mb-0">
            <li class="col-md-4 col-6">
              <div class="mb-0">Title</div>
              <div class="fw-bold">{{data?.biographicData?.title || '-'}}</div>
            </li>
            <li class="col-md-4 col-6">
              <div class="mb-0">First name</div>
              <div class="fw-bold">{{data?.biographicData?.firstName || '-'}}</div>
            </li>
            <li class="col-md-4 col-6">
              <div class="mb-0">Last name</div>
              <div class="fw-bold">{{data?.biographicData?.lastName || '-'}}</div>
            </li>
            <li class="col-md-4 col-6">
              <div class="mb-0">Middle name</div>
              <div class="fw-bold">{{data?.biographicData?.middleName || '-'}}</div>
            </li>
            <li class="col-md-4 col-6">
              <div class="mb-0">Other name</div>
              <div class="fw-bold">{{data?.biographicData?.otherName || '-'}}</div>
            </li>
            <li class="col-md-4 col-6">
              <div class="mb-0">Maiden name</div>
              <div class="fw-bold">{{data?.biographicData?.maidenName || '-'}}</div>
            </li>
            <li class="col-md-4 col-6">
              <div class="mb-0">Date of Birth</div>
              <div class="fw-bold">{{data?.biographicData?.dateOfBirth || '-' | dateOnly}}</div>
            </li>
            <li class="col-md-4 col-6">
              <div class="mb-0">Gender</div>
              <div class="fw-bold">{{data?.biographicData?.gender || '-'}}</div>
            </li>
            <li class="col-md-4 col-6">
              <div class="mb-0">Email Address</div>
              <div class="fw-bold" style="word-wrap: break-word">{{data?.biographicData?.emailAddress || '-' }}</div>
            </li>
            <li class="col-md-4 col-6">
              <div class="mb-0">Telephone No.</div>
              <div class="fw-bold">{{data?.biographicData?.telephoneNo || '-' }}</div>
            </li>
            <li class="col-md-4 col-6">
              <div class="mb-0">Height</div>
              <div class="fw-bold">{{data?.biographicData?.height}}cm</div>
            </li>
            <li class="col-md-4 col-6">
              <div class="mb-0">NIN</div>
              <div class="fw-bold">{{data?.uin}}</div>
            </li>
            <li class="col-md-4 col-6">
              <div class="mb-0">Nationality</div>
              <div class="fw-bold">{{data?.biographicData?.nationality || '-' | upperCase }}</div>
            </li>
            <li class="col-md-4 col-6">
              <div class="mb-0">Birth country</div>
              <div class="fw-bold">{{data?.biographicData?.birthCountry || '-' | upperCase }}</div>
            </li>
            <li class="col-md-4 col-6">
              <div class="mb-0">Birth state</div>
              <div class="fw-bold">{{data?.biographicData?.birthState || '-'}}</div>
            </li>
            <li class="col-md-4 col-6">
              <div class="mb-0">Birth LGA</div>
              <div class="fw-bold">{{data?.biographicData?.birthLga || '-'}}</div>
            </li>
            <li class="col-md-4 col-6">
              <div class="mb-0">Residence Address Line 1</div>
              <div class="fw-bold">{{data?.biographicData?.residenceAddressLine1 || '-'}}</div>
            </li>
            <li class="col-md-4 col-6">
              <div class="mb-0">Residence Address Line 2</div>
              <div class="fw-bold">{{data?.biographicData?.residenceAddressLine2 == null ? '-': data?.biographicData?.residenceAddressLine2}}</div>
            </li>
            <li class="col-md-4 col-6">
              <div class="mb-0">Residence Country</div>
              <div class="fw-bold">{{data?.biographicData?.residenceCountry || '-' | upperCase }}</div>
            </li>
            <li class="col-md-4 col-6">
              <div class="mb-0">Residence LGA</div>
              <div class="fw-bold">{{data?.biographicData?.residenceLga || '-' | upperCase }}</div>
            </li>
            <li class="col-md-4 col-6">
              <div class="mb-0">Residence State</div>
              <div class="fw-bold">{{data?.biographicData?.residenceState | upperCase }}</div>
            </li>
            <li class="col-md-4 col-6">
              <div class="mb-0">Residence Status</div>
              <div class="fw-bold">{{data?.biographicData?.residenceStatus || '-' | upperCase  }}</div>
            </li>
            <li class="col-md-4 col-6">
              <div class="mb-0">Signature</div>
              <ng-container *ngIf="signature; else noSignature">
                <div><img class="object-fit-cover rounded" width="70" height="70"
                          [src]="isHistory ? (signature?.data?.id | historyFileUrl)  : (signature?.data?.id | fileUrl) "/></div>
              </ng-container>
              <ng-template #noSignature>
                <div> - </div>
              </ng-template>
            </li>
          </ul>
        </div>
      </div>
      <div class="tab-pane fade" id="pills-other-info" role="tabpanel" tabindex="0">
        <div class="bg-input px-3 py-4 rounded">
          <h5 class="fs-18 text-link mb-3">Other information</h5>

          <ul class="list-unstyled row gy-3 gx-3 fw-15 mb-0">

            <li class="col-md-3 col-6">
              <div class="mb-0">Prev. first name</div>
              <div class="fw-bold">{{data?.biographicData?.previousFirstName  || '-' }}</div>
            </li>
            <li class="col-md-3 col-6">
              <div class="mb-0">Prev. last name</div>
              <div class="fw-bold">{{data?.biographicData?.previousLastName || '-' }}</div>
            </li>
            <li class="col-md-3 col-6">
              <div class="mb-0">Prev. middle name</div>
              <div class="fw-bold">{{data?.biographicData?.previousMiddleName || '-' }}</div>
            </li>
            <li class="col-md-3 col-6">
              <div class="mb-0"></div>
              <div class="fw-bold"></div>
            </li>

            <li class="col-md-3 col-6">
              <div class="mb-0">Father origin country</div>
              <div class="fw-bold">{{data?.biographicData?.fatherOriginCountry || '-'}}</div>
            </li>

            <li class="col-md-3 col-6">
              <div class="mb-0">Father origin state</div>
              <div class="fw-bold">{{data?.biographicData?.fatherOriginState || '-'}}</div>
            </li>

            <li class="col-md-3 col-6">
              <div class="mb-0">Father origin LGA</div>
              <div class="fw-bold">{{data?.biographicData?.fatherOriginLga || '-'}}</div>
            </li>

            <li class="col-md-3 col-6">
              <div class="mb-0">Father origin Place</div>
              <div class="fw-bold">{{data?.biographicData?.fatherOriginPlace || '-'}}</div>
            </li>

            <li class="col-md-3 col-6">
              <div class="mb-0">Mother origin country</div>
              <div class="fw-bold">{{data?.biographicData?.motherOriginCountry || '-'}}</div>
            </li>

            <li class="col-md-3 col-6">
              <div class="mb-0">Mother origin state</div>
              <div class="fw-bold">{{data?.biographicData?.motherOriginState || '-'}}</div>
            </li>

            <li class="col-md-3 col-6">
              <div class="mb-0">Mother origin LGA</div>
              <div class="fw-bold">{{data?.biographicData?.motherOriginLga || '-'}}</div>
            </li>

            <li class="col-md-3 col-6">
              <div class="mb-0">Mother origin Place</div>
              <div class="fw-bold">{{data?.biographicData?.motherOriginPlace || '-'}}</div>
            </li>

            <li class="col-md-3 col-6">
              <div class="mb-0">Marital status</div>
              <div class="fw-bold">{{data?.biographicData?.maritalStatus || '-' }}</div>
            </li>
            <li class="col-md-3 col-6">
              <div class="mb-0">Spouse NIN</div>
              <div class="fw-bold">{{data?.biographicData?.spouseNin || '-' }}</div>
            </li>
            <li class="col-md-3 col-6">
              <div class="mb-0">Religion</div>
              <div class="fw-bold">{{data?.biographicData?.religion || '-' }}</div>
            </li>


            <li class="col-md-3 col-6">
              <div class="mb-0"></div>
              <div class="fw-bold"></div>
            </li>

            <li class="col-md-3 col-6">
              <div class="mb-0">Native Spoken Lang</div>
              <div class="fw-bold">{{data?.biographicData?.nativeSpokenLanguage || '-' }}</div>
            </li>
            <li class="col-md-3 col-6">
              <div class="mb-0">Other Spoken Lang</div>
              <div class="fw-bold">{{data?.biographicData?.otherSpokenLanguage || 'none' }}</div>
            </li>
            <li class="col-md-3 col-6">
              <div class="mb-0">Read Write Lang</div>
              <div class="fw-bold">{{data?.biographicData?.readWriteLanguage || '-' }}</div>
            </li>

            <li class="col-md-3 col-6">
              <div class="mb-0"></div>
              <div class="fw-bold"></div>
            </li>


            <li class="col-md-3 col-6">
              <div class="mb-0">Education Level</div>
              <div class="fw-bold">{{data?.biographicData?.educationLevel || '-' }}</div>
            </li>
            <li class="col-md-3 col-6">
              <div class="mb-0">Employment Status</div>
              <div class="fw-bold">{{data?.biographicData?.employmentStatus || '-' }}</div>
            </li>
            <li class="col-md-3 col-6">
              <div class="mb-0">Profession</div>
              <div class="fw-bold">{{data?.biographicData?.profession || '-' }}</div>
            </li>

            <li class="col-md-3 col-6">
              <div class="mb-0"></div>
              <div class="fw-bold"></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
</div>
