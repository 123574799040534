import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class FingerprintScanConfigService {

    config: FingerprintConfig;

    constructor() {
    }

    getPollingInterval(): number {
        return this.config?.pollingInterval;
    }

    getMaxAttempts(): number {
        return this.config?.maxPollingAttempts;
    }

    getNumberOfTrials(): number {
        return this.config?.numberOfTrials
    }

    async loadConfig(config: FingerprintConfig): Promise<void> {

        if (config) {
            this.config = config;
        }

    }
}

export interface FingerprintConfig {
    fingerToScan?: number
    numberOfPrintsToScan?: number
    numberOfTrials?: number
    numberOfTrialsLeft?: number
    nfiQuality?: number,
    pollingInterval?: number,
    maxPollingAttempts?: number
}