/**
 * MVRD API
 * The MVRD RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: airekeola@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PortalUser } from './portal-user';


export interface ContextualData { 
    id?: number;
    createdAt?: string;
    lastUpdatedAt?: string;
    createdBy?: PortalUser;
    lastUpdatedBy?: PortalUser;
    dateDeactivated?: string;
    status?: ContextualData.StatusEnum;
    deactivatedBy?: PortalUser;
    operatorUserId?: string;
    enrollmentDate?: string;
    enrollmentTrackingId?: string;
    enrollmentCentralRecordId?: string;
    enrollmentInsertionTime?: string;
    processingInsertionTime?: string;
    deathEnrollmentTrackingId?: string;
    personalizationResult?: string;
}
export namespace ContextualData {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum
    };
}


