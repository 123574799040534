import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-success-modal-dialog',
  templateUrl: './success-modal-dialog.component.html',
  styleUrls: ['./success-modal-dialog.component.css']
})
export class SuccessModalDialogComponent implements OnInit {


  @Input() message = 'Your request was successfully processed!'
  @Output()
  onSuccessEmitter = new EventEmitter();
  constructor(private bsModalRef: BsModalRef) { }

  ngOnInit(): void {
  }

  close() {
    this.onSuccessEmitter.emit();
    this.bsModalRef.hide();
  }
}
