import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";
import {Setting} from "../../../../sdk/mvrd-api-sdk";

@Component({
  selector: 'app-success-dialog',
  templateUrl: './success-dialog.component.html',
  styleUrls: ['./success-dialog.component.css']
})
export class SuccessDialogComponent implements OnInit {

  @Input()
  settings: Setting;

  @Output()
  onSuccessEmitter = new EventEmitter();

  constructor(
    private bsModalRef: BsModalRef
  ) {
  }

  ngOnInit(): void {
  }


  close(): void {
    this.onSuccessEmitter.emit();
    this.bsModalRef.hide();
  }


}
