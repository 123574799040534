import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-site-footer',
  templateUrl: './site-footer.component.html',
  styleUrls: ['./site-footer.component.css']
})
export class SiteFooterComponent implements OnInit {

  nimcEmail: string = "info@nimc.gov.ng";
  nimcTel: string = "+234 700 225 5646";

  constructor() { }

  ngOnInit(): void {
  }

  get currentYear(){
    return new Date().getFullYear()
  }

}
