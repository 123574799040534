import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from "../../../shared/authentication/authentication-service.service";

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  constructor(private authenticationService: AuthenticationService) {
  }

  ngOnInit(): void {
    // console.log('Loggin out...');
    // this.authenticationService.logout(window.location.origin).subscribe(v => {
    //
    // });
  }

}
