import {APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {LottieModule} from 'ngx-lottie';
import {PopoverModule} from 'ngx-bootstrap/popover'
import {TooltipModule} from 'ngx-bootstrap/tooltip'
import {KeycloakAngularModule, KeycloakEventType, KeycloakService} from 'keycloak-angular';
import {AuthenticationService} from "./shared/authentication/authentication-service.service";

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {SiteHeaderComponent} from './modules/layout/site-header/site-header.component';
import {SiteFooterComponent} from './modules/layout/site-footer/site-footer.component';
import {IntranetSiteHeaderComponent} from './modules/layout/intranet-site-header/intranet-site-header.component';
import {NotFoundComponent} from './modules/extranet/error-pages/not-found/not-found.component';
import {ForbiddenComponent} from './modules/extranet/error-pages/forbidden/forbidden.component';
import {SharedModule} from './shared/shared.module';
import {HomepageComponent} from './modules/extranet/homepage/homepage.component';
import {environment} from "../environments/environment";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {HttpInterceptorService} from "./shared/authentication/http-interceptor.service";
import {BASE_PATH} from "../../sdk/mvrd-api-sdk";
import {ModalModule} from "ngx-bootstrap/modal";
import {NgBootstrapFormValidationModule} from "ng-bootstrap-form-validation";
import {LogoutComponent} from './modules/extranet/logout/logout.component';
import {BsDatepickerModule} from "ngx-bootstrap/datepicker";
import {FingerprintAuthModule} from "./modules/extranet/fingerprint-auth/fingerprint-auth.module";

export function playerFactory() {
  return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web/build/player/lottie_svg');
}


function initializeKeycloak(keycloak: KeycloakService, authenticationService: AuthenticationService) {
  keycloak.keycloakEvents$.subscribe(async value => {
    if (value.type === KeycloakEventType.OnReady) {

    } else if (value.type === KeycloakEventType.OnAuthSuccess) {
      await authenticationService.fetchUser().toPromise();
    }
  });
  return () =>
    keycloak.init({
      config: environment.keycloakConfig,
      initOptions: {
        onLoad: 'login-required',
        silentCheckSsoRedirectUri:
          window.location.origin + '/assets/silent-check-sso.html',
        messageReceiveTimeout: 500_000,
      },
    });
}

@NgModule({
  declarations: [
    AppComponent,
    SiteHeaderComponent,
    SiteFooterComponent,
    IntranetSiteHeaderComponent,
    NotFoundComponent,
    ForbiddenComponent,
    HomepageComponent,
    LogoutComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    LottieModule.forRoot({player: playerFactory}),
    PopoverModule.forRoot(),
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    SharedModule,
    BsDatepickerModule.forRoot(),
    NgBootstrapFormValidationModule.forRoot(),
    KeycloakAngularModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FingerprintAuthModule.forRoot()
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService, AuthenticationService]
    },
    {provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true},
    {
      provide: BASE_PATH,
      useValue: environment.apiBaseUrl
    }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
}
