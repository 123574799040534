import {Component, Input} from '@angular/core';

@Component({
  selector: 'lib-circular-progress',
  templateUrl: './circular-progress.component.html',
  styleUrls: ['./circular-progress.component.css']
})
export class CircularProgressComponent {

  @Input()
  percent = 0;

  @Input()
  radius = 45;

  @Input()
  outerStrokeColor = '#01873A'
}
