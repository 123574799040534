<div class="bd-5">
    <div class="d-flex align-items-center">
      <p>
        Show
      </p>
      <div class="px-1 py-md-1 mx-2 d-flex justify-content-between form-group ">
        <select class="form-control" [(ngModel)]="pageLength">
          <option [selected]="i == 0" *ngFor="let val of pageLengths; let i = index" [value]="val">
            {{val}}

          </option>


        </select>

      </div>

      <p> per page</p>
    </div>

</div>
