import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FingerprintAuthComponent} from './fingerprint-auth.component';
import {ScanHelpSectionComponent} from "./components/scan-help-section/scan-help-section.component";
import {CircularProgressComponent} from "./components/circular-progress/circular-progress.component";
import {ScanSingleFingerprintComponent} from "./components/scan-single-fingerprint/scan-single-fingerprint.component";
import {LottieModule} from "ngx-lottie";
import {FingerprintSvgComponent} from "./components/fingerprint-svg/fingerprint-svg.component";
import {SharedModule} from "../../../shared/shared.module";
import {TrialTrackerComponent} from './components/trial-tracker/trial-tracker.component';
import {NgCircleProgressModule} from "ng-circle-progress";
import {FINGERPRINT_AUTH_CONFIG} from "./variables";
import {RouterModule} from "@angular/router";

const defaultConfig: FINGERPRINT_AUTH_CONFIG = {
  attemptInstruction: "After 3 failed attempts, your account will be locked. Please contact the admin for assistance",
  captureInstruction: "Please follow the steps and image guide below to successfully complete biometric authentication",
  leftHandLabel: "Left Hand",
  rightHandLabel: "Right Hand",
  title: "Biometric Authentication",
  trialAttemptCount: 3
}

@NgModule({
  declarations: [
    FingerprintAuthComponent,
    ScanSingleFingerprintComponent,
    CircularProgressComponent,
    ScanHelpSectionComponent,
    FingerprintSvgComponent,
    TrialTrackerComponent
  ],
  imports: [
    CommonModule,
    LottieModule,
    SharedModule,
    NgCircleProgressModule.forRoot({
      radius: 80,
      outerStrokeWidth: 3,
      showInnerStroke: false,
      outerStrokeColor: "#78C000",
      animationDuration: 300
    }),
    RouterModule
  ],
  exports: [
    FingerprintAuthComponent,
    ScanSingleFingerprintComponent,
  ]
})


export class FingerprintAuthModule {
  static forRoot(config?: FINGERPRINT_AUTH_CONFIG): ModuleWithProviders<FingerprintAuthModule> {
    const mergedConfig = {...defaultConfig, ...config};
    return {
      ngModule: FingerprintAuthModule,
      providers: [
        {provide: FINGERPRINT_AUTH_CONFIG, useValue: mergedConfig}
      ]
    };
  }
}
