/**
 * MVRD API
 * The MVRD RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: airekeola@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PortalUser } from './portal-user';


export interface BiographicData { 
    id?: number;
    createdAt?: string;
    lastUpdatedAt?: string;
    createdBy?: PortalUser;
    lastUpdatedBy?: PortalUser;
    dateDeactivated?: string;
    status?: BiographicData.StatusEnum;
    deactivatedBy?: PortalUser;
    title?: string;
    firstName?: string;
    lastName?: string;
    middleName?: string;
    otherName?: string;
    maidenName?: string;
    previousFirstName?: string;
    previousLastName?: string;
    previousMiddleName?: string;
    dateOfBirth?: string;
    gender?: BiographicData.GenderEnum;
    birthCountry?: string;
    birthState?: string;
    birthLga?: string;
    maritalStatus?: string;
    spouseNin?: string;
    height?: string;
    residenceStatus?: string;
    religion?: string;
    emailAddress?: string;
    telephoneNo?: string;
    nativeSpokenLanguage?: string;
    otherSpokenLanguage?: string;
    readWriteLanguage?: string;
    educationLevel?: string;
    profession?: string;
    employmentStatus?: string;
    nationality?: string;
    originPlace?: string;
    originLga?: string;
    originState?: string;
    originCountry?: string;
    fatherOriginPlace?: string;
    fatherOriginLga?: string;
    fatherOriginState?: string;
    fatherOriginCountry?: string;
    motherOriginPlace?: string;
    motherOriginLga?: string;
    motherOriginState?: string;
    motherOriginCountry?: string;
    residenceAddressLine1?: string;
    residenceAddressLine2?: string;
    residenceTown?: string;
    residenceLga?: string;
    residenceState?: string;
    residenceCountry?: string;
    disability?: string;
    physicalFeature?: string;
    guardianSurname?: string;
    guardianFirstName?: string;
    guardianMidleName?: string;
    guardianNin?: string;
    nextOfKinSurname?: string;
    nextOfKinFirstName?: string;
    nextOfKinMiddleName?: string;
    nextOfKinNin?: string;
    nextOfKinAddressLine1?: string;
    nextOfKinAddressLine2?: string;
    nextOfKinPostCode?: string;
    nextOfKinTown?: string;
    nextOfKinLga?: string;
    nextOfKinRelation?: string;
    nextOfKinCountry?: string;
    fatherSurname?: string;
    fatherFirstName?: string;
    fatherMiddleName?: string;
    fatherNin?: string;
    motherSurname?: string;
    motherFirstName?: string;
    motherMiddleName?: string;
    motherMaidenName?: string;
    motherNin?: string;
}
export namespace BiographicData {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum
    };
    export type GenderEnum = 'MALE' | 'FEMALE' | 'UNSPECIFIED' | 'OTHER';
    export const GenderEnum = {
        Male: 'MALE' as GenderEnum,
        Female: 'FEMALE' as GenderEnum,
        Unspecified: 'UNSPECIFIED' as GenderEnum,
        Other: 'OTHER' as GenderEnum
    };
}


