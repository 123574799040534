/**
 * MVRD API
 * The MVRD RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: airekeola@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface EmeStatPojo { 
    totalPerson?: number;
    totalRecordsPublished?: number;
    totalConflictingRecords?: number;
    recordsAwaitingAdjudication?: number;
    recordsAwaitingPublication?: number;
    pendingSyncToFileServer?: number;
    pendingBiometricFromCorpus?: number;
    totalIdentities?: number;
    publishedToRealTimeTopic?: number;
    lastUpdated?: string;
    totalBiometricsInMvrd?: number;
    totalBiometricsInFileService?: number;
    latestPublishedTrackingId?: string;
    latestPublishedInsertionTimestamp?: string;
}

