import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-fingerprint-svg',
  templateUrl: './fingerprint-svg.component.html',
  styleUrls: ['./fingerprint-svg.component.css']
})
export class FingerprintSvgComponent implements OnInit {

  @Input() capturedFingers:number[] = [];
  @Input() currentCaptureIndex:number;

  constructor() { }

  ngOnInit(): void {
  }

  isCaptured(index){
    if (this.capturedFingers?.includes(index)) return true;

    return false
  }

  isCurrentCaptureIndex(index){
    if (this.currentCaptureIndex === index) return true;

    return false
  }

  checkFingerState(index){
    if (this.isCurrentCaptureIndex(index)) {
      return 'finger_capturing';
    } else if (this.isCaptured(index)) {
      return 'finger_captured';
    } else {
      return '';
    }
  }

}
