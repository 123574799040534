import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'historyFileUrl'
})
export class HistoryFileUrlPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    return '/api/files/history/'+value;
  }

}
