import {Component, Input, OnInit} from '@angular/core';
import {BiometricsData, PersonDetailsPojo} from "../../../../../sdk/mvrd-api-sdk";
import {FileUrlPipe} from "../../pipes/file-url.pipe";

@Component({
  selector: 'app-biographic',
  templateUrl: './biographic.component.html',
  styleUrls: ['./biographic.component.css']
})
export class BiographicComponent implements OnInit {

  @Input() data: PersonDetailsPojo;
  @Input() isHistory: boolean = false;
  signature: BiometricsData;
  constructor() { }

  ngOnInit(): void {
    // @ts-ignore
    this.signature = this.data?.biometricData?.find(value => value.type == 'SIGNATURE');
    console.log("Signature ==>", this.data.biometricData)
    console.log("Signature ==>", this.signature)
  }

  getImage(id: number) {
    return new FileUrlPipe().transform(id);
  }

}
