export enum Constant {
    OTP_CONSTANT = 'iuq7u93u3c4cy2384ubbd893284ndejk',
    OTP_REQUESTED = 'dbwe28394u32094x3u398u2e0xwrerew',
    OTP_MESSAGE = 'wfiuibuih983iub23uib23ueh23eh23ehe',
    OTP_SMS_ACTIVE = 'dudbu9e093e3298r8932r3289h23d3hd',
    OTP_LENGTH = 'ewrbbr32ur239b2398b2398db23ubd28d92db',
    OTP_DELIVERY_MODE = 'obwon3490n9430fn930fj43f9043nf0943f',
    OTP_DELIVERY_MODE_SMS = 'fhoihf390rh9043n90434390rn4r',
    OTP_DELIVERY_MODE_EMAIL = 'nionfeh90439rh43r438r43r09jr0922',
    HAS_OTP = 'HAS_OTP',
}
