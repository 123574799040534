import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {KeycloakAuthGuard, KeycloakService} from 'keycloak-angular';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {AuthenticationService} from '../authentication/authentication-service.service';
import {AccountMembershipPojo} from "../../../../sdk/mvrd-api-sdk";
import AccountTypeEnum = AccountMembershipPojo.AccountTypeEnum;
import PermissionsEnum = AccountMembershipPojo.PermissionsEnum;

@Injectable({
  providedIn: 'root'
})
export class LoggedInGuard extends KeycloakAuthGuard {
  public override readonly router: Router;

  constructor(
    router: Router,
    protected readonly keycloak: KeycloakService,
    protected readonly authenticationService: AuthenticationService
  ) {
    super(router,keycloak);
    this.router = router;

    // console.log('IN auth guard!');
  }

  public async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    if (!this.authenticated) {
      await this.authenticationService.login({redirectUri: window.location.origin + state.url});
      return false;
    } else {
      return this.authenticationService.fetchUser()
        .pipe(map((user => {
          if (!user) {
            // this.authenticationService.forbidAccess();
            window.location.href = '/forbidden';
            // this.callLogin();
            return false;
          }
          if (user.requiresPasswordUpdate) {
            this.router.navigate(['change-password']);
            return false;
          }
          const allowedPermissions: RoutePermissionRestriction[] = route.data.permissions;
          if (!allowedPermissions || allowedPermissions.length < 1) {
            return true;
          }
          for (let i = 0; i < allowedPermissions.length; i++) {
            let hasRestrictedRoles = (allowedPermissions[i].roles?.length ?? 0) > 0;
            let hasRestrictedPermissions = (allowedPermissions[i].permissions?.length ?? 0) > 0;
            if (this.authenticationService.hasAccountType(allowedPermissions[i].accountType)) {
              if (!hasRestrictedRoles && !hasRestrictedPermissions) {
                return true;
              }
              if (hasRestrictedRoles && this.authenticationService.hasAnyRole(allowedPermissions[i].roles)) {
                return true;
              } else if (hasRestrictedPermissions && this.authenticationService.hasAnyPermission(allowedPermissions[i].permissions)) {
                return true;
              }
            }
          }
          this.authenticationService.forbidAccess();
          return false;
        })))
        .pipe(catchError((err: any, caught: Observable<any>) => {
          // this.authenticationService.forbidAccess();
          window.location.href = '/forbidden';
          // this.callLogin();
          return of(false);
        })).toPromise();
    }
  }


  callLogin() {
    this.authenticationService.login({
      redirectUri: window.location.origin + '/dashboard',
      prompt: 'login'
    });
  }
}

export interface RoutePermissionRestriction {
  accountType: AccountTypeEnum
  roles: string[]
  permissions: string[] | PermissionsEnum[]
}
