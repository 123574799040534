<div class="p-md-4 p-4 card-bg rounded fs-14">
    <div class="bg-input px-3 py-4 rounded">
      <h5 class="fs-18 text-link mb-3">Meta Information</h5>
        <ul class="list-unstyled row gy-3 gx-3 fw-15 mb-0">
<!--          <li class="col-md-4">-->
<!--            <div class="mb-0">Enroller User Id</div>-->
<!--            <div class="fw-bold">{{data.contextualData.operatorUserId}}</div>-->
<!--          </li>-->
          <li class="col-md-4">
            <div class="mb-0">Central Record ID</div>
            <div class="fw-bold">{{data.contextualData.enrollmentCentralRecordId}}</div>
          </li>
          <li class="col-md-4">
            <div class="mb-0">Enrollment Date</div>
            <div class="fw-bold">{{data.contextualData.enrollmentDate}}</div>
          </li>
<!--          <li class="col-md-4">-->
<!--            <div class="mb-0">Enrollment Time</div>-->
<!--            <div class="fw-bold">15:29:32</div>-->
<!--          </li>-->
          <li class="col-md-4">
            <div class="mb-0">Enrollment Tracking ID</div>
            <div class="fw-bold">{{data.contextualData.enrollmentTrackingId}}</div>
          </li>
          <li class="col-md-4">
            <div class="mb-0">NIN Issuance Date</div>
            <div class="fw-bold">{{data.contextualData.enrollmentInsertionTime}}</div>
          </li>
          <li class="col-md-4">
            <div class="mb-0">Enrollment Insertion Time (to back-end):</div>
            <div class="fw-bold">{{data.contextualData.processingInsertionTime | shortestDate}}</div>
          </li>
<!--          <li class="col-md-4">-->
<!--            <div class="mb-0">Fingerprint Collection</div>-->
<!--            <div class="fw-bold">bypass</div>-->
<!--          </li>-->
        </ul>
    </div>
</div>
