/**
 * MVRD API
 * The MVRD RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: airekeola@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PersonSearchFilter { 
    offset?: number;
    limit?: number;
    order?: PersonSearchFilter.OrderEnum;
    orderColumn?: string;
    enrollmentStartDate?: string;
    enrollmentEndDate?: string;
    mvrdCreationStartDate?: string;
    mvrdCreationEndDate?: string;
    uin?: string;
    history?: boolean;
}
export namespace PersonSearchFilter {
    export type OrderEnum = 'ASC' | 'DESC';
    export const OrderEnum = {
        Asc: 'ASC' as OrderEnum,
        Desc: 'DESC' as OrderEnum
    };
}


