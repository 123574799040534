import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from "../../../shared/authentication/authentication-service.service";

@Component({
  selector: 'app-intranet-site-header',
  templateUrl: './intranet-site-header.component.html',
  styleUrls: ['./intranet-site-header.component.css']
})
export class IntranetSiteHeaderComponent implements OnInit {

  constructor(
    private authenticationService: AuthenticationService
  ) {
  }

  ngOnInit() {
  }

  canViewManagePerson() {
    return this.authenticationService.hasAnyRole(['ADMIN', 'IDENTITY_MANAGER', 'IDENTITY_REVIEWER'])
  }

  canViewSetting() {
    return this.authenticationService.hasPermission('VIEW_SETTING');
  }

  canViewAuditTrail() {
    return this.authenticationService.hasAnyPermission(['VIEW_AUDIT_TRAIL'])
  }
}
