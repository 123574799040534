<div class="p-md-4 p-4 card-bg rounded fs-14">
          
    <div class="form-group mb-4">
      <label>Document type</label>
      <div class="row gx-1">
        <div class="col">
          <select class="form-control">
            <option>-- Select document type -- </option>
            <option>International passport</option>
            <option>Birth Certificate</option>
            <option>Drivers License</option>
          </select>
        </div>
        <div class="col-auto">
          <button (click)="searchForDocument()" class="btn btn-primary px-3 h-100"><span class="iconify icon" data-icon="akar-icons:search" ></span> <span class="d-none">Search</span> </button>
        </div>
      </div>
    </div>
    
    
    <ng-container *ngIf="!searching && !documentData">
        <div class="bg-input px-3 py-4 rounded fw-15 white-space-nowrap d-flex align-items-center justify-content-center" style="min-height: 400px;">
            <div class="text-center">
                <span class="iconify text-header mb-2" data-icon="fluent:document-error-16-regular" style="font-size: 56px;"></span>
                <div class="fs-22 fw-bold">No data found</div>
                <div class="fs-14">Search for a document </div>
            </div>
        </div>
    </ng-container>
    
    <ng-container *ngIf="searching">
        <div class="bg-input px-3 py-4 rounded fw-15 white-space-nowrap d-flex align-items-center justify-content-center" style="min-height: 400px;">
            <div class="text-center">
                <span class="iconify text-primary mb-2" data-icon="eos-icons:loading" style="font-size: 56px;"></span>
                <div class="fs-22 fw-bold">Please wait</div>
                <div class="fs-14">Searching for document...</div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="documentData && !searching">
        <div class="bg-input px-3 py-4 rounded fw-15 white-space-nowrap" style="min-height: 400px;">
            <div class="d-flex justify-content-between mb-4">
              <div>
                <img class="rounded object-fit-cover" src="../assets/img/placeholder-img.svg" height="104px" width="104px" style="min-width: 104px;" />
              </div>
              <div>
                <button class="my-1 mx-1 btn-unset-all d-flex align-items-center flex-nowrap text-decoration-underline">
                  <span class="iconify fs-20 me-2" data-icon="vscode-icons:file-type-pdf2"></span> 
                  Download PDF 
                  <!-- <span class="ms-2 border p-1 rounded d-flex align-items-center justify-content-center" style="height: 24px; width: 24px;"><span class="iconify fs-14" data-icon="akar-icons:download"></span></span> -->
                </button>
              </div>
            </div>
            
            <div class="mb-4 pb-1">
              <div class="d-flex justify-content-between align-items-start mb-2">
                <div class="me-3">Document type:</div>
                <div class="fw-bold">Birth certificate</div>
              </div>
              <div class="d-flex justify-content-between align-items-start mb-2">
                <div class="me-3">Page count:</div>
                <div class="fw-bold">1</div>
              </div>
              <div class="d-flex justify-content-between align-items-start mb-2">
                <div class="me-3">Format:</div>
                <div class="fw-bold">PNG</div>
              </div>
              <div class="d-flex justify-content-between align-items-start mb-2">
                <div class="me-3">Size:</div>
                <div class="fw-bold">125kb</div>
              </div>
              <div class="d-flex justify-content-between align-items-start mb-2">
                <div class="me-3">Resolution:</div>
                <div class="fw-bold">600x600</div>
              </div>
              <div class="d-flex justify-content-between align-items-start mb-2">
                <div class="me-4">Ref ID:</div>
                <div class="fw-bold text-truncate">NMC-FC24983424998343982343982343982343982343982</div>
              </div>
            </div>
      
            
            <div>
              <div class="d-flex justify-content-between align-items-start mb-2">
                <div class="me-3">Enrolled by:</div>
                <div class="fw-bold">Olawale Folashade</div>
              </div>
              <div class="d-flex justify-content-between align-items-start mb-2">
                <div class="me-3">Enrollment Date:</div>
                <div class="fw-bold">24-02-2022</div>
              </div>
              <div class="d-flex justify-content-between align-items-start mb-2">
                <div class="me-3">Enrollment Time:</div>
                <div class="fw-bold">01:29:32 PM</div>
              </div>
            </div>
        </div>
    </ng-container>
    
</div>