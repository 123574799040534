import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberString'
})
export class NumberStringPipe implements PipeTransform {

  public transform(value: any, args?: any): any {
    if (value === null || value === undefined) {
      return value;
    }
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

}
