<div [style.opacity]="opacity" id="preloader">
  <div class="d-flex vh-100 justify-content-center align-items-center flex-column">
    <div><span class="iconify text-primary" data-icon="eos-icons:loading" style="font-size: 40px;"></span></div>
    <div *ngIf="customText" [innerHTML]="customText"
         style="margin-top: 6px; text-transform: capitalize; font-weight: lighter; text-align: center;">
      <!-- Custom Text -->
    </div>
  </div>
</div>

