/**
 * MVRD API
 * The MVRD RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: airekeola@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PersonSearchResponsePojo { 
    surname?: string;
    uin?: string;
    firstName?: string;
    enrolmentDate?: string;
    nationality?: string;
    dateOfBirth?: string;
    height?: string;
    id?: number;
    createdAt?: string;
    gender?: PersonSearchResponsePojo.GenderEnum;
}
export namespace PersonSearchResponsePojo {
    export type GenderEnum = 'MALE' | 'FEMALE' | 'UNSPECIFIED' | 'OTHER';
    export const GenderEnum = {
        Male: 'MALE' as GenderEnum,
        Female: 'FEMALE' as GenderEnum,
        Unspecified: 'UNSPECIFIED' as GenderEnum,
        Other: 'OTHER' as GenderEnum
    };
}


