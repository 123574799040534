import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, ActivationStart, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {AuthenticationService} from "../../../shared/authentication/authentication-service.service";
import {KeycloakService} from "keycloak-angular";
import {PageManager} from "../../../shared/misc/page-manager";
import {UserAccount} from "../../../shared/models/user-account.model";
import {User} from "../../../shared/models/user/user.model";

@Component({
  selector: 'app-site-header',
  templateUrl: './site-header.component.html',
  styleUrls: ['./site-header.component.css']
})
export class SiteHeaderComponent implements OnInit {

  @Input()
  user: User | null | undefined;
  routeData: any;
  loading: boolean = false;
  currentAccount: UserAccount | null | undefined;

  constructor(private router: Router, route: ActivatedRoute,
              private authenticationService: AuthenticationService,
              private keycloak: KeycloakService, private pageManager: PageManager) {
    this.router.events.subscribe((value) => {
      if (value instanceof ActivationStart) {
        this.routeData = value.snapshot.data;
      } else if (value instanceof NavigationStart) {
        this.loading = true;
      } else if (value instanceof NavigationEnd) {
        this.loading = false;
      }
    });
  }

  ngOnInit(): void {
    this.pageManager.currentUserAccount$
      .subscribe(userAccount => {
        this.currentAccount = userAccount;
      });
  }

  async login() {
    this.authenticationService.login({

      redirectUri: window.location.origin,
      prompt: 'login'

    });
  }

 async logout() {
    // await this.authenticationService.logout().subscribe(value => {
    // this.authenticationService.logout(window.location.origin).subscribe(v => {
    //
    // });
   this.authenticationService.logout().subscribe(v => {

   });
  }

  callLogin() {
    this.authenticationService.login({
      redirectUri: window.location.origin + '/dashboard',
      prompt: 'login'
    });
  }

  goToDashboard(){
    this.router.navigate(['/dashboard'])
  }

  setHeaderClass() {
    let headerClass = '';

    if (this.routeData?.fixedHeader) {
      headerClass += ' header-fixed fixed-on-scroll'
    }
    if (!(this.routeData?.isExtranet)) {
      headerClass += ' intranet-nav'
    }

    return headerClass;
  }

}
