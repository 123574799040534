import {InjectionToken} from "@angular/core";
import {FingerprintPluginHandler} from "./handlers/fingerprint-plugin-handler";

export class Variables {

}

export type FingerTypeEnum =
  'RIGHT_THUMB'
  | 'RIGHT_INDEX'
  | 'RIGHT_MIDDLE'
  | 'RIGHT_RING'
  | 'RIGHT_LITTLE'
  | 'LEFT_THUMB'
  | 'LEFT_INDEX'
  | 'LEFT_MIDDLE'
  | 'LEFT_RING'
  | 'LEFT_LITTLE';
export const FingerTypeEnum = {
  RightThumb: 'RIGHT_THUMB' as FingerTypeEnum,
  RightIndex: 'RIGHT_INDEX' as FingerTypeEnum,
  RightMiddle: 'RIGHT_MIDDLE' as FingerTypeEnum,
  RightRing: 'RIGHT_RING' as FingerTypeEnum,
  RightLittle: 'RIGHT_LITTLE' as FingerTypeEnum,
  LeftThumb: 'LEFT_THUMB' as FingerTypeEnum,
  LeftIndex: 'LEFT_INDEX' as FingerTypeEnum,
  LeftMiddle: 'LEFT_MIDDLE' as FingerTypeEnum,
  LeftRing: 'LEFT_RING' as FingerTypeEnum,
  LeftLittle: 'LEFT_LITTLE' as FingerTypeEnum
};

export type AUTH_STATUS =
  'NO_PLUGIN_DETECTED'
  | 'NO_SCANNER_DETECTED'
  | 'READY_TO_SCAN'
  | 'SCANNING'
  | 'SCANNING_SUCCESS'
  | 'SCANNING_FAILURE'
  | 'VERIFYING'
  | 'VERIFICATION_FAILURE'
  | 'READY_TO_LOGIN';

export const AUTH_STATUS = {
  NO_PLUGIN_DETECTED: 'NO_PLUGIN_DETECTED' as AUTH_STATUS,
  NO_SCANNER_DETECTED: 'NO_SCANNER_DETECTED' as AUTH_STATUS,
  READY_TO_SCAN: 'READY_TO_SCAN' as AUTH_STATUS,
  SCANNING: 'SCANNING' as AUTH_STATUS,
  SCANNING_SUCCESS: 'SCANNING_SUCCESS' as AUTH_STATUS,
  SCANNING_FAILURE: 'SCANNING_FAILURE' as AUTH_STATUS,
  VERIFYING: 'VERIFYING' as AUTH_STATUS,
  VERIFICATION_FAILURE: 'VERIFICATION_FAILURE' as AUTH_STATUS,
  READY_TO_LOGIN: 'READY_TO_LOGIN' as AUTH_STATUS,
}

export interface FINGERPRINT_AUTH_CONFIG {
  title?: string;
  attemptInstruction?: string;
  leftHandLabel?: string;
  rightHandLabel?: string;
  captureInstruction?: string;
  trialAttemptCount?: number;
}

export enum AlertType {
  success
  ,
  error
  ,
  info
  ,
  primary
}

export const FINGERPRINT_PLUGIN_HANDLER = new InjectionToken<FingerprintPluginHandler>('FingerprintPluginHandler');
export const FINGERPRINT_AUTH_CONFIG = new InjectionToken<FINGERPRINT_AUTH_CONFIG>('FingerprintPluginHandler');
