<div class="mt-2 card rounded-5 border-0">
    <div class="card-body">
        <div class="step fs-12">
            <ng-container *ngFor="let tab of trials; index as i">
                <div class="mark-line cursor-pointer">
                    <div [ngClass]="{active: logged(i)}"
                         class="status-mark">

                <span class="count" >
                    {{ i + 1 }}
                </span>
                    </div>
                </div>
                <div class="right">
                    <div [ngClass]="{
                        active: logged(i),
                        line: i < trials.length - 1
                      }"
                    ></div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
