/**
 * MVRD API
 * The MVRD RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: airekeola@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BiometricsData } from './biometrics-data';
import { ContextualData } from './contextual-data';
import { RequestPojo } from './request-pojo';
import { BiographicData } from './biographic-data';


export interface PersonHistoryPojo { 
    uin?: string;
    transactionID?: string;
    publishedToEme?: boolean;
    identityStatus?: PersonHistoryPojo.IdentityStatusEnum;
    biographicData?: BiographicData;
    spokenLanguage?: string;
    contextualData?: ContextualData;
    biometricData?: Array<BiometricsData>;
    request?: RequestPojo;
    personId?: number;
    history?: boolean;
}
export namespace PersonHistoryPojo {
    export type IdentityStatusEnum = 'CLAIMED' | 'VALID' | 'INVALID' | 'REVOKED' | 'SUSPENDED';
    export const IdentityStatusEnum = {
        Claimed: 'CLAIMED' as IdentityStatusEnum,
        Valid: 'VALID' as IdentityStatusEnum,
        Invalid: 'INVALID' as IdentityStatusEnum,
        Revoked: 'REVOKED' as IdentityStatusEnum,
        Suspended: 'SUSPENDED' as IdentityStatusEnum
    };
}


