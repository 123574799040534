import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'upperCase'
})
export class UppercasePipe implements PipeTransform {

  public transform(value: any, ...args: any[]): unknown {
    if (value === null || value === undefined) {
      return value;
    }
    return value.toUpperCase();
  }

}
