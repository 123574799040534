export * from './activity-log-search-controller.service';
import { ActivityLogSearchControllerService } from './activity-log-search-controller.service';
export * from './audit-trail-controller.service';
import { AuditTrailControllerService } from './audit-trail-controller.service';
export * from './bw-file-controller.service';
import { BwFileControllerService } from './bw-file-controller.service';
export * from './dashboard-controller.service';
import { DashboardControllerService } from './dashboard-controller.service';
export * from './eme-stat-controller.service';
import { EmeStatControllerService } from './eme-stat-controller.service';
export * from './heath-controller.service';
import { HeathControllerService } from './heath-controller.service';
export * from './person-controller.service';
import { PersonControllerService } from './person-controller.service';
export * from './request-controller.service';
import { RequestControllerService } from './request-controller.service';
export * from './settings-controller.service';
import { SettingsControllerService } from './settings-controller.service';
export * from './user-auth-controller.service';
import { UserAuthControllerService } from './user-auth-controller.service';
export * from './user-controller.service';
import { UserControllerService } from './user-controller.service';
export const APIS = [ActivityLogSearchControllerService, AuditTrailControllerService, BwFileControllerService, DashboardControllerService, EmeStatControllerService, HeathControllerService, PersonControllerService, RequestControllerService, SettingsControllerService, UserAuthControllerService, UserControllerService];
