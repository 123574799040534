/**
 * MVRD API
 * The MVRD RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: airekeola@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AuditTrailPojo { 
    txId?: string;
    actor?: string;
    userAgent?: string;
    eventType?: AuditTrailPojo.EventTypeEnum;
    recordType?: string;
    eventStatus?: AuditTrailPojo.EventStatusEnum;
    dataServerTime?: string;
    resource?: string;
    sourceIP?: string;
    description?: string;
    actorUUID?: string;
    recipientEmail?: string;
    role?: string;
    privileges?: string;
    recordId?: number;
    resourceUri?: string;
    otherDetails?: string;
    recipientName?: string;
    actorMaskedNIN?: string;
    authenticationMethod?: string;
    systemName?: string;
    lastLoginTime?: string;
    actorName?: string;
    attemptedAttack?: boolean;
}
export namespace AuditTrailPojo {
    export type EventTypeEnum = 'CREATE' | 'DELETE' | 'UPDATE' | 'SELECT' | 'LOGIN' | 'LOGOUT' | 'OPERATION_LOG' | 'GENERIC_LOG' | 'SIEM' | 'BIOMETRIC_LOGIN';
    export const EventTypeEnum = {
        Create: 'CREATE' as EventTypeEnum,
        Delete: 'DELETE' as EventTypeEnum,
        Update: 'UPDATE' as EventTypeEnum,
        Select: 'SELECT' as EventTypeEnum,
        Login: 'LOGIN' as EventTypeEnum,
        Logout: 'LOGOUT' as EventTypeEnum,
        OperationLog: 'OPERATION_LOG' as EventTypeEnum,
        GenericLog: 'GENERIC_LOG' as EventTypeEnum,
        Siem: 'SIEM' as EventTypeEnum,
        BiometricLogin: 'BIOMETRIC_LOGIN' as EventTypeEnum
    };
    export type EventStatusEnum = 'SUCCESSFUL' | 'FAILED';
    export const EventStatusEnum = {
        Successful: 'SUCCESSFUL' as EventStatusEnum,
        Failed: 'FAILED' as EventStatusEnum
    };
}


