<div class="modal-content">
  <div class="modal-body">
    <div class="text-center mx-auto pb-3" style="max-width: 378px;">
      <div style="min-height: 150px;">
        <ng-lottie [options]="{ path: './assets/lottie/success.json', loop: false, autoplay: true }" width="150px"
                   height="150px" containerClass="mx-auto"></ng-lottie>
      </div>
      <div class="fs-18 mb-3">The value for <span class="fw-bold">{{settings.name | underscoreToSpace}}</span> has
        been updated successfully
      </div>
      <button class="btn btn-success" (click)="close()">Done</button>
    </div>
  </div>
</div>