<app-full-page-loader *ngIf="!userResponseGotten"></app-full-page-loader>
<div [class.hidden]="userResponseGotten">
  <div class="fill">
    <app-site-header [user]="user"></app-site-header>
    <app-intranet-site-header *ngIf="!routeData?.isExtranet"></app-intranet-site-header>
    <div [ngClass]="{'pb-5 mb-5': !routeData?.isExtranet}">
      <router-outlet></router-outlet>
    </div>
  </div>
  <app-site-footer *ngIf="!routeData?.hideFooter"></app-site-footer>
</div>
