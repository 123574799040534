import {Component, Input, OnInit} from '@angular/core';
import {BiometricsData, PersonDetailsPojo} from "../../../../../sdk/mvrd-api-sdk";
import {HistoryFileUrlPipe} from "../../pipes/history-file-url.pipe";
import {FileUrlPipe} from "../../pipes/file-url.pipe";

@Component({
  selector: 'app-biometric-data',
  templateUrl: './biometric-data.component.html',
  styleUrls: ['./biometric-data.component.css']
})
export class BiometricDataComponent implements OnInit {

  @Input() data: PersonDetailsPojo
  @Input() isHistory: boolean = false;
  faceImage: BiometricsData;

  constructor() {
  }

  ngOnInit(): void {
    this.faceImage = this.data?.biometricData?.find(value => value.type == 'FACE');
  }

  getImage(details: PersonDetailsPojo) {
    const id = details?.biometricData?.find(value => value.type == 'FACE')?.data?.id;
    return this.isHistory ? new HistoryFileUrlPipe().transform(id) : new FileUrlPipe().transform(id);

  }

}
