<circle-progress
        [percent]="percent"
        [radius]="radius"
        [outerStrokeColor]="outerStrokeColor"
        [animation]="true"
        [animationDuration]="300"
        [showInnerStroke]="false"
        [showTitle]="false"
        [showUnits]="false"
        [showSubtitle]="false"
></circle-progress>
