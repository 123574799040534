import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import {AuthenticationService} from "../../../../shared/authentication/authentication-service.service";
import {User} from "../../../../shared/models/user/user.model";

@Component({
  selector: 'app-forbidden',
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.css']
})
export class ForbiddenComponent implements OnInit {

  user: User | undefined | null;

  constructor(private router: Router, private authenticationService: AuthenticationService) { }

  ngOnInit() {
    this.authenticationService.getUser()
      .subscribe(value => {
        this.user = value;
      });
  }

  takeMeBack() {
    if (this.user /* Check if logged in */) {
      window.location.href = '/dashboard';
    } else {
      this.authenticationService.login({prompt: 'login', redirectUri: window.location.origin + '/dashboard'});
    }
  }

}
