import {Component, Input, OnInit} from '@angular/core';
import {FingerprintScanService} from "../../services/fingerprint-scan.service";

@Component({
    selector: 'app-trial-tracker',
    templateUrl: './trial-tracker.component.html',
    styleUrls: ['./trial-tracker.component.scss']
})
export class TrialTrackerComponent implements OnInit {

    @Input()
    numberOfTrials = 0

    @Input()
    numberOfTrialsLeft = 3

    constructor(private fingerprintScanService: FingerprintScanService) {

    }

    ngOnInit(): void {
        this.initListeners();
    }

    initListeners(): void {
        this.fingerprintScanService.trialAttemptAsObservable
            .subscribe(trial => {

                this.numberOfTrials = trial;
            })

        this.fingerprintScanService.trialAttemptLeftAsObservable
            .subscribe(trial => {

                this.numberOfTrialsLeft = trial;
            })
    }

    get trials(): Array<number> {
        return this.getRange(this.numberOfTrialsLeft + this.numberOfTrials);
    }

    getRange(n: number): number[] {
        return Array.from({length: n}, (_, index) => index + 1);
    }

    logged(index: number): boolean {
        return this.numberOfTrials > (index)
    }
}
