/**
 * MVRD API
 * The MVRD RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: airekeola@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BwBinaryData } from './bw-binary-data';


export interface BiometricsData { 
    type?: BiometricsData.TypeEnum;
    subType?: string;
    data?: BwBinaryData;
}
export namespace BiometricsData {
    export type TypeEnum = 'FACE' | 'FINGERPRINT' | 'IRIS' | 'VOICE' | 'SIGNATURE';
    export const TypeEnum = {
        Face: 'FACE' as TypeEnum,
        Fingerprint: 'FINGERPRINT' as TypeEnum,
        Iris: 'IRIS' as TypeEnum,
        Voice: 'VOICE' as TypeEnum,
        Signature: 'SIGNATURE' as TypeEnum
    };
}


