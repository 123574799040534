import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from "../../../../shared/authentication/authentication-service.service";
import {User} from "../../../../shared/models/user/user.model";

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit {

  user: User | undefined | null;

  constructor(private router: Router,
              private authenticationService: AuthenticationService,) {
  }

  ngOnInit() {
    this.authenticationService.getUser()
      .subscribe(value => {
        this.user = value;
      });
  }

  goBackHome() {
    if (this.user) {
      this.router.navigate(['/dashboard'])
    } else {
      this.router.navigate(['/'])
    }
  }

}
