import {Component, Input, OnInit} from '@angular/core';
import {PersonDetailsPojo} from "../../../../../sdk/mvrd-api-sdk";

@Component({
  selector: 'app-document-data',
  templateUrl: './document-data.component.html',
  styleUrls: ['./document-data.component.css']
})
export class DocumentDataComponent implements OnInit {

  searching:boolean = false;
  documentData:any;


  @Input() data: PersonDetailsPojo
  constructor() { }

  ngOnInit(): void {
  }


  searchForDocument(){
    this.searching = true;

    setTimeout(() => {
      this.searching = false;
      // this.documentData = '...';
    }, 3000);
  }

}
