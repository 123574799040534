<div class="container pt-4 pt-md-3 pb-4 mb-2">
  <div class="welcome-wrapper flex-wrap flex-md-nowrap">
  </div>

  <div class="dash-menu">
    <div class="dash-container">
      <div class="menu-toggle-btn-wrapper">
        <button class="menu-toggle-btn" data-hvas-target="#dash-navlist" data-hvas-toggle-class="show">
          <span class="iconify icon" data-icon="heroicons-solid:menu"></span>
          Menu
        </button>
      </div>

      <ul id="dash-navlist" class="dash-menu-navlist justify-content-center mb-3">
        <li><a class="nav-item" [routerLinkActive]="'active'" [routerLink]="['/dashboard']"
               data-hvas-target="#dash-navlist" data-hvas-remove-class="show">Dashboard</a></li>
        <li *ngIf="canViewManagePerson()"><a [routerLinkActive]="'active'" [routerLink]="['/manage-person']"
                                             class="nav-item" data-hvas-remove-class="show"
                                             data-hvas-target="#dash-navlist">Manage Person</a></li>
        <!--        <li><a class="nav-item" [routerLinkActive]="'active'" [routerLink]="['/access-manager']" data-hvas-target="#dash-navlist" data-hvas-remove-class="show">Access Manager</a></li>-->
        <li *ngIf="canViewAuditTrail()"><a [routerLinkActive]="'active'" [routerLink]="['/audit-trail']"
                                           class="nav-item" data-hvas-remove-class="show"
                                           data-hvas-target="#dash-navlist">Audit Trail</a></li>
        <li *ngIf="canViewSetting()"><a [routerLinkActive]="'active'" [routerLink]="['/settings']" class="nav-item"
                                        data-hvas-remove-class="show" data-hvas-target="#dash-navlist">Settings</a></li>
      </ul>
    </div>
  </div>
</div>
