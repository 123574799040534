import {Component} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivationEnd, ActivationStart, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {AuthenticationService} from "./shared/authentication/authentication-service.service";
import {KeycloakService} from "keycloak-angular";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  routeData: any;
  loading: boolean = false;
  userResponseGotten = false;
  user;

  constructor(public router: Router,
              public title: Title,
              private keycloakService: KeycloakService,
              private authenticationService: AuthenticationService,
  ) {
    this.router.events.subscribe((value) => {
      if (value instanceof ActivationEnd) {
        this.routeData = value?.snapshot?.data;
        // this.title.setTitle(`MVRD | ${this.routeData?.title || ''}`);
      } else if (value instanceof NavigationStart) {
        this.loading = true;
      } else if (value instanceof NavigationEnd) {
        this.loading = false;
      }
    });
    this.authenticationService.getUser().subscribe(value => {
      this.user = value;
      this.userResponseGotten = true;
    }, error => {
      this.userResponseGotten = true;
    })
  }
}
