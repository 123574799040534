/**
 * MVRD API
 * The MVRD RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: airekeola@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface RequestDto { 
    note?: string;
    identityStatus?: RequestDto.IdentityStatusEnum;
    personId?: number;
    supportingDocumentBase64?: string;
}
export namespace RequestDto {
    export type IdentityStatusEnum = 'CLAIMED' | 'VALID' | 'INVALID' | 'REVOKED' | 'SUSPENDED';
    export const IdentityStatusEnum = {
        Claimed: 'CLAIMED' as IdentityStatusEnum,
        Valid: 'VALID' as IdentityStatusEnum,
        Invalid: 'INVALID' as IdentityStatusEnum,
        Revoked: 'REVOKED' as IdentityStatusEnum,
        Suspended: 'SUSPENDED' as IdentityStatusEnum
    };
}


