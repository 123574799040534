import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ForbiddenComponent} from './modules/extranet/error-pages/forbidden/forbidden.component';
import {NotFoundComponent} from './modules/extranet/error-pages/not-found/not-found.component';
import {HomepageComponent} from './modules/extranet/homepage/homepage.component';
import {LoggedInGuard} from "./shared/guard/logged-in.guard";
import {LogoutComponent} from "./modules/extranet/logout/logout.component";
import {MultiFactorAuthenticationGuard} from "./shared/guard/multi-factor-authentication-guard.service";

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: HomepageComponent,
    data: {
      isExtranet: true,
      fixedHeader: true
    },
  },
  {
    path: '',
    loadChildren: () => import('./modules/intranet/intranet.module').then((m) => m.IntranetModule),
    data: {
      isExtranet: false,
      title: 'MVRD'
    },
    canActivate: [LoggedInGuard, MultiFactorAuthenticationGuard]

  },
  {
    path: 'forbidden',
    component: ForbiddenComponent,
    data: {
      isExtranet: true
    },
  },
  {
    path: 'logout',
    component: LogoutComponent,
    data: {
      isExtranet: true
    },
  },
  {
    path: '2fa/fingerprint',
    data: {
      isExtranet: true,
      hideLayout: true,
      hideFooter: true
    },
    canActivate: [LoggedInGuard],
    loadChildren: () => import('./shared/fingerprint-authentication/fingerprint-authentication-routing.module').then(m => m.FingerprintAuthenticationRoutingModule),
  },
  {
    path: 'download-plugin',
    data: {
      isExtranet: true,
      hideLayout: true
    },
    canActivate: [],
    loadChildren: () => import('./modules/extranet/biometric-auth-plugin/biometric-auth-plugin.module').then(m => m.BiometricAuthPluginModule),
  },
  {
    path: '**',
    component: NotFoundComponent,
    data: {
      isExtranet: false
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
