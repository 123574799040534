/**
 * MVRD API
 * The MVRD RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: airekeola@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ActivityLogSearchFilter { 
    offset?: number;
    limit?: number;
    order?: ActivityLogSearchFilter.OrderEnum;
    orderColumn?: string;
    userName?: string;
    phoneNumber?: string;
    recipient?: string;
    userType?: string;
    activity?: ActivityLogSearchFilter.ActivityEnum;
    status?: ActivityLogSearchFilter.StatusEnum;
    action?: ActivityLogSearchFilter.ActionEnum;
    startDate?: string;
    endDate?: string;
    ipAddress?: string;
    description?: string;
}
export namespace ActivityLogSearchFilter {
    export type OrderEnum = 'ASC' | 'DESC';
    export const OrderEnum = {
        Asc: 'ASC' as OrderEnum,
        Desc: 'DESC' as OrderEnum
    };
    export type ActivityEnum = 'LOGIN' | 'LOGOUT' | 'VALIDATE_PERSON' | 'INVALIDATE_PERSON' | 'REVOKE_PERSON' | 'CLAIM_PERSON' | 'SUSPEND_PERSON' | 'DISAPPROVE_REQUEST' | 'UPDATE_REQUEST' | 'VIEW_PERSON' | 'VIEW_PERSON_HISTORY' | 'UPDATE_SETTING' | 'DISAPPROVE_VALIDATE_REQUEST' | 'APPROVE_VALIDATE_REQUEST' | 'DISAPPROVE_SUSPEND_REQUEST' | 'APPROVE_SUSPEND_REQUEST' | 'SEARCH_PERSON' | 'SEARCH_REQUEST';
    export const ActivityEnum = {
        Login: 'LOGIN' as ActivityEnum,
        Logout: 'LOGOUT' as ActivityEnum,
        ValidatePerson: 'VALIDATE_PERSON' as ActivityEnum,
        InvalidatePerson: 'INVALIDATE_PERSON' as ActivityEnum,
        RevokePerson: 'REVOKE_PERSON' as ActivityEnum,
        ClaimPerson: 'CLAIM_PERSON' as ActivityEnum,
        SuspendPerson: 'SUSPEND_PERSON' as ActivityEnum,
        DisapproveRequest: 'DISAPPROVE_REQUEST' as ActivityEnum,
        UpdateRequest: 'UPDATE_REQUEST' as ActivityEnum,
        ViewPerson: 'VIEW_PERSON' as ActivityEnum,
        ViewPersonHistory: 'VIEW_PERSON_HISTORY' as ActivityEnum,
        UpdateSetting: 'UPDATE_SETTING' as ActivityEnum,
        DisapproveValidateRequest: 'DISAPPROVE_VALIDATE_REQUEST' as ActivityEnum,
        ApproveValidateRequest: 'APPROVE_VALIDATE_REQUEST' as ActivityEnum,
        DisapproveSuspendRequest: 'DISAPPROVE_SUSPEND_REQUEST' as ActivityEnum,
        ApproveSuspendRequest: 'APPROVE_SUSPEND_REQUEST' as ActivityEnum,
        SearchPerson: 'SEARCH_PERSON' as ActivityEnum,
        SearchRequest: 'SEARCH_REQUEST' as ActivityEnum
    };
    export type StatusEnum = 'PENDING' | 'FAILED' | 'SUCCESSFUL';
    export const StatusEnum = {
        Pending: 'PENDING' as StatusEnum,
        Failed: 'FAILED' as StatusEnum,
        Successful: 'SUCCESSFUL' as StatusEnum
    };
    export type ActionEnum = 'LOGIN' | 'LOGOUT' | 'VALIDATE_PERSON' | 'INVALIDATE_PERSON' | 'REVOKE_PERSON' | 'CLAIM_PERSON' | 'SUSPEND_PERSON' | 'DISAPPROVE_REQUEST' | 'UPDATE_REQUEST' | 'VIEW_PERSON' | 'VIEW_PERSON_HISTORY' | 'UPDATE_SETTING' | 'DISAPPROVE_VALIDATE_REQUEST' | 'APPROVE_VALIDATE_REQUEST' | 'DISAPPROVE_SUSPEND_REQUEST' | 'APPROVE_SUSPEND_REQUEST' | 'SEARCH_PERSON' | 'SEARCH_REQUEST';
    export const ActionEnum = {
        Login: 'LOGIN' as ActionEnum,
        Logout: 'LOGOUT' as ActionEnum,
        ValidatePerson: 'VALIDATE_PERSON' as ActionEnum,
        InvalidatePerson: 'INVALIDATE_PERSON' as ActionEnum,
        RevokePerson: 'REVOKE_PERSON' as ActionEnum,
        ClaimPerson: 'CLAIM_PERSON' as ActionEnum,
        SuspendPerson: 'SUSPEND_PERSON' as ActionEnum,
        DisapproveRequest: 'DISAPPROVE_REQUEST' as ActionEnum,
        UpdateRequest: 'UPDATE_REQUEST' as ActionEnum,
        ViewPerson: 'VIEW_PERSON' as ActionEnum,
        ViewPersonHistory: 'VIEW_PERSON_HISTORY' as ActionEnum,
        UpdateSetting: 'UPDATE_SETTING' as ActionEnum,
        DisapproveValidateRequest: 'DISAPPROVE_VALIDATE_REQUEST' as ActionEnum,
        ApproveValidateRequest: 'APPROVE_VALIDATE_REQUEST' as ActionEnum,
        DisapproveSuspendRequest: 'DISAPPROVE_SUSPEND_REQUEST' as ActionEnum,
        ApproveSuspendRequest: 'APPROVE_SUSPEND_REQUEST' as ActionEnum,
        SearchPerson: 'SEARCH_PERSON' as ActionEnum,
        SearchRequest: 'SEARCH_REQUEST' as ActionEnum
    };
}


