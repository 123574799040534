/**
 * MVRD API
 * The MVRD RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: airekeola@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RequestPojo } from './request-pojo';


export interface RequestSearchResponsePojo { 
    requestTrackingId?: string;
    uin?: string;
    requesterUin?: string;
    requesterName?: string;
    approvalStatus?: RequestSearchResponsePojo.ApprovalStatusEnum;
    createdAt?: string;
    requestType?: RequestSearchResponsePojo.RequestTypeEnum;
    identityStatus?: RequestSearchResponsePojo.IdentityStatusEnum;
    id?: number;
    personId?: number;
    requestPojo?: RequestPojo;
}
export namespace RequestSearchResponsePojo {
    export type ApprovalStatusEnum = 'APPROVED' | 'PENDING' | 'DISAPPROVED';
    export const ApprovalStatusEnum = {
        Approved: 'APPROVED' as ApprovalStatusEnum,
        Pending: 'PENDING' as ApprovalStatusEnum,
        Disapproved: 'DISAPPROVED' as ApprovalStatusEnum
    };
    export type RequestTypeEnum = 'UPDATE_PERSON_STATUS' | 'UPDATE_PERSON_DETAILS';
    export const RequestTypeEnum = {
        Status: 'UPDATE_PERSON_STATUS' as RequestTypeEnum,
        Details: 'UPDATE_PERSON_DETAILS' as RequestTypeEnum
    };
    export type IdentityStatusEnum = 'CLAIMED' | 'VALID' | 'INVALID' | 'REVOKED' | 'SUSPENDED';
    export const IdentityStatusEnum = {
        Claimed: 'CLAIMED' as IdentityStatusEnum,
        Valid: 'VALID' as IdentityStatusEnum,
        Invalid: 'INVALID' as IdentityStatusEnum,
        Revoked: 'REVOKED' as IdentityStatusEnum,
        Suspended: 'SUSPENDED' as IdentityStatusEnum
    };
}


