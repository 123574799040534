<div class="p-md-4 p-4 card-bg rounded fs-14">
  <div class="d-flex justify-content-center">
    <ul class="nav nav-pills nav-pills-white-bg mb-3" id="pills-tab" role="tablist">
      <li class="nav-item" role="presentation">
        <button class="nav-link active" id="pills-face-data-tab" data-bs-toggle="pill" data-bs-target="#pills-face-data" type="button" role="tab">Face Data</button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" id="pills-iris-tab" data-bs-toggle="pill" data-bs-target="#pills-iris" type="button" role="tab">Iris</button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" id="pills-voice-tab" data-bs-toggle="pill" data-bs-target="#pills-voice" type="button" role="tab">Voice</button>
      </li>
    </ul>
  </div>
  <div class="tab-content" id="pills-tabContent">
    <div class="tab-pane fade show active" id="pills-face-data" role="tabpanel" tabindex="0">
        <div class="row gy-3 my-4 py-3 justify-content-center">
            <div class="col-md-5 text-center text-md-end">
                <img src="{{getImage(data)}}" height="246px" width="246px" style="min-width: 246px;" class="ms-md-auto mx-auto me-md-0 rounded" />
            </div>
            <div class="col-md-7">
                <div class="bg-input px-3 py-4 rounded mx-auto ms-md-0" style="max-width: 400px;">
                    <h3 class="fs-18 mb-3">Meta information</h3>

                    <ul class="list-unstyled fs-14">
                      <li class="d-flex justify-content-between align-items-start mb-2">
                          <div class="me-3">Format:</div>
                          <div class="fw-bold text-end">.png</div>
                      </li>
                      <li class="d-flex justify-content-between align-items-start mb-2">
                          <div class="me-3">Size:</div>
                          <div class="fw-bold text-end"> 21 KB</div>
                      </li>
                      <li class="d-flex justify-content-between align-items-start mb-2">
                          <div class="me-3">Mime Type:</div>
                          <div class="fw-bold text-end">image/jpeg</div>
                      </li>
                      <li class="d-flex justify-content-between align-items-start mb-2">
                          <div class="me-3">Resolution:</div>
                          <div class="fw-bold text-end">600x600</div>
                      </li>
                      <li class="d-flex justify-content-between align-items-start mb-2">
                          <div class="me-4 white-space-nowrap">Image ID:</div>
                          <div class="fw-bold text-truncate text-end">{{data?.biometricData?.data?.id}}</div>
                      </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="tab-pane fade show " id="pills-iris" role="tabpanel" tabindex="2">
        <div class="row gy-3 my-4 py-3 justify-content-center">
          <div class="text-center py-3 my-2">
            <span class="iconify text-header mb-2" data-icon="fluent:document-error-16-regular" style="font-size: 40px;"></span>
            <div class="fs-18 fw-bold">No Iris data found</div>
            <div class="fs-15">...</div>
          </div>
        </div>
    </div>
    <div class="tab-pane fade show " id="pills-voice" role="tabpanel" tabindex="3">
        <div class="row gy-3 my-4 py-3 justify-content-center">
          <div class="text-center py-3 my-2">
            <span class="iconify text-header mb-2" data-icon="fluent:document-error-16-regular" style="font-size: 40px;"></span>
            <div class="fs-18 fw-bold">No Voice data found</div>
            <div class="fs-15">...</div>
          </div>
        </div>
    </div>

    <div class="tab-pane fade show " id="pills-fingerprint" role="tabpanel" tabindex="1">
      <div class="row gy-3 my-4 py-3 justify-content-center">
        <div class="text-center py-3 my-2">
          <span class="iconify text-header mb-2" data-icon="fluent:document-error-16-regular"
                style="font-size: 40px;"></span>
          <div class="fs-18 fw-bold">No Fingerprint data found</div>
          <div class="fs-15">...</div>
        </div>
      </div>
    </div>
    <div class="tab-pane fade show " id="pills-iris" role="tabpanel" tabindex="2">
      <div class="row gy-3 my-4 py-3 justify-content-center">
        <div class="text-center py-3 my-2">
          <span class="iconify text-header mb-2" data-icon="fluent:document-error-16-regular"
                style="font-size: 40px;"></span>
          <div class="fs-18 fw-bold">No Iris data found</div>
          <div class="fs-15">...</div>
        </div>
      </div>
    </div>
    <div class="tab-pane fade show " id="pills-voice" role="tabpanel" tabindex="3">
      <div class="row gy-3 my-4 py-3 justify-content-center">
        <div class="text-center py-3 my-2">
          <span class="iconify text-header mb-2" data-icon="fluent:document-error-16-regular"
                style="font-size: 40px;"></span>
          <div class="fs-18 fw-bold">No Voice data found</div>
          <div class="fs-15">...</div>
        </div>
      </div>
    </div>
  </div>
</div>
