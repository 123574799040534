import { Component, Input, OnInit } from '@angular/core';
import {PersonDetailsPojo} from "../../../../../sdk/mvrd-api-sdk";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-enrollment-data',
  templateUrl: './enrollment-data.component.html',
  styleUrls: ['./enrollment-data.component.css'],
  providers: [DatePipe]
})
export class EnrollmentDataComponent implements OnInit {

  @Input () data: PersonDetailsPojo


  constructor() { }

  ngOnInit(): void {
  }

}
