/**
 * MVRD API
 * The MVRD RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: airekeola@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NameAndCodeDto } from './name-and-code-dto';
import { Country } from './country';


export interface AddressPojo { 
    zipCode?: string;
    city?: NameAndCodeDto;
    state?: NameAndCodeDto;
    country?: Country;
    streetAddress?: string;
    houseNumber?: string;
}

