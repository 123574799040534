import {Component, OnInit} from '@angular/core';
import {FingerprintScanService} from "../../services/fingerprint-scan.service";
import {distinctUntilChanged} from "rxjs/operators";
import {Router} from "@angular/router";

@Component({
  selector: 'lib-scan-help-section',
  templateUrl: './scan-help-section.component.html',
  styleUrls: ['./scan-help-section.component.css']
})
export class ScanHelpSectionComponent implements OnInit {

  fpConnected = false;
  pluginDetected = false;
  pluginLink: '/download-plugin';

  constructor(private fingerprintScanService: FingerprintScanService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.initListeners();
  }

  initListeners(): void {
    this.fingerprintScanService.fpConnectionStatusAsObservable
      .pipe(distinctUntilChanged())
      .subscribe(status => {

        this.fpConnected = status && status == true;
      })

    this.fingerprintScanService.pluginDetectionStatusAsObservable
      .pipe(distinctUntilChanged())
      .subscribe(status => {

        this.pluginDetected = status && status == true;
      })
  }

  goToPlugin(): void {
    this.router.navigate(['download-plugin']);
  }

}
