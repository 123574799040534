import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LottieModule} from "ngx-lottie";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgBootstrapFormValidationModule} from "ng-bootstrap-form-validation";
import {TooltipModule} from "ngx-bootstrap/tooltip";

import {BiographicComponent} from './person-components/biographic/biographic.component';
import {DocumentDataComponent} from './person-components/document-data/document-data.component';
import {BiometricDataComponent} from './person-components/biometric-data/biometric-data.component';
import {NumberStringPipe} from "./pipes/number-string.pipe";
import {SuccessDialogComponent} from './success-dialog/success-dialog.component';
import {UnderscoreToSpacePipe} from "./pipes/underscore-to-space.pipe";
import {SettingConfirmationDialogComponent} from './setting-confirmation-dialog/setting-confirmation-dialog.component';
import {SkeletonLoaderComponent} from './components/skeleton-loader/skeleton-loader.component';
import {DateOnlyPipe} from "./pipes/date-only.pipe";
import {TimeOnlyPipe} from "./pipes/time-only.pipe";
import {PaginationModule} from "ngx-bootstrap/pagination";
import {PageSizeComponent} from "./page-size/page-size.component";
import {UppercasePipe} from './pipes/uppercase.pipe';
import {FileUrlPipe} from "./pipes/file-url.pipe";
import {SuccessModalDialogComponent} from './components/success-modal-dialog/success-modal-dialog.component';
import {ShortestDatePipe} from "./pipes/shortest-date.pipe";
import {EnrollmentDataComponent} from './person-components/enrollment-data/enrollment-data.component';
import {FullPageLoaderComponent} from "./components/full-page-loader/full-page-loader.component";
import {LoaderComponent} from "./components/loader/loader.component";
import {HistoryFileUrlPipe} from "./pipes/history-file-url.pipe";
import {TotalRecordsComponent} from "./components/total-records/total-records.component";
import {DatetimePipe} from "./pipes/datetime.pipe";
import {FingerprintAuthenticationModule} from "./fingerprint-authentication/fingerprint-authentication.module";


@NgModule({
  declarations: [
    BiographicComponent,
    TotalRecordsComponent,
    DocumentDataComponent,
    BiometricDataComponent,
    NumberStringPipe,
    SuccessDialogComponent,
    UnderscoreToSpacePipe,
    SettingConfirmationDialogComponent,
    SkeletonLoaderComponent,
    DateOnlyPipe,
    PageSizeComponent,
    TimeOnlyPipe,
    FileUrlPipe,
    HistoryFileUrlPipe,
    UppercasePipe,
    SuccessModalDialogComponent,
    ShortestDatePipe,
    EnrollmentDataComponent,
    FullPageLoaderComponent,
    LoaderComponent,
    DatetimePipe
  ],
  imports: [
    CommonModule,
    LottieModule,
    ReactiveFormsModule,
    NgBootstrapFormValidationModule,
    PaginationModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    TooltipModule
  ],
  exports: [
    BiographicComponent,
    TotalRecordsComponent,
    FormsModule,
    DocumentDataComponent,
    BiometricDataComponent,
    EnrollmentDataComponent,
    NumberStringPipe,
    UppercasePipe,
    PaginationModule,
    SuccessDialogComponent,
    PageSizeComponent,
    SettingConfirmationDialogComponent,
    UnderscoreToSpacePipe,
    SkeletonLoaderComponent,
    FileUrlPipe,
    HistoryFileUrlPipe,
    DateOnlyPipe,
    TimeOnlyPipe,
    ShortestDatePipe,
    FullPageLoaderComponent,
    LoaderComponent,
    DatetimePipe
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {
}
