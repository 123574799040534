/**
 * MVRD API
 * The MVRD RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: airekeola@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface RequestSearchFilter { 
    offset?: number;
    limit?: number;
    order?: RequestSearchFilter.OrderEnum;
    orderColumn?: string;
    startDate?: string;
    endDate?: string;
    trackingId?: string;
    identityStatus?: RequestSearchFilter.IdentityStatusEnum;
    requestType?: RequestSearchFilter.RequestTypeEnum;
    approvalStatus?: RequestSearchFilter.ApprovalStatusEnum;
}
export namespace RequestSearchFilter {
    export type OrderEnum = 'ASC' | 'DESC';
    export const OrderEnum = {
        Asc: 'ASC' as OrderEnum,
        Desc: 'DESC' as OrderEnum
    };
    export type IdentityStatusEnum = 'CLAIMED' | 'VALID' | 'INVALID' | 'REVOKED' | 'SUSPENDED';
    export const IdentityStatusEnum = {
        Claimed: 'CLAIMED' as IdentityStatusEnum,
        Valid: 'VALID' as IdentityStatusEnum,
        Invalid: 'INVALID' as IdentityStatusEnum,
        Revoked: 'REVOKED' as IdentityStatusEnum,
        Suspended: 'SUSPENDED' as IdentityStatusEnum
    };
    export type RequestTypeEnum = 'UPDATE_PERSON_STATUS' | 'UPDATE_PERSON_DETAILS';
    export const RequestTypeEnum = {
        Status: 'UPDATE_PERSON_STATUS' as RequestTypeEnum,
        Details: 'UPDATE_PERSON_DETAILS' as RequestTypeEnum
    };
    export type ApprovalStatusEnum = 'APPROVED' | 'PENDING' | 'DISAPPROVED';
    export const ApprovalStatusEnum = {
        Approved: 'APPROVED' as ApprovalStatusEnum,
        Pending: 'PENDING' as ApprovalStatusEnum,
        Disapproved: 'DISAPPROVED' as ApprovalStatusEnum
    };
}


