<footer class="footer-border-top py-4" xmlns="http://www.w3.org/1999/html">
  <div class="container-fluid">
    <div class="d-flex flex-row justify-content-around mx-5">
      <div>
        <div class="d-flex align-items-center flex-sm-nowrap flex-wrap">
          <div class="me-3 my-3">
            <img src="assets/img/NImcfooter.svg" class="mw-100 d-dark-none w-auto" alt="NIMC Logo"
                 height="70px"/>
            <img src="assets/img/NImcfooter-light.svg" class="mw-100 d-light-none w-auto" alt="NIMC Logo"
                 height="70px"/>
          </div>
          <div>
            <p class="fs-14 mb-1"> &copy; Copyright 2022 - {{currentYear}}. </p>
            <h5 class="text-success fw-bold fs-20 mb-1"> National Identity Management Commission. </h5>
            <h5 class="fw-medium fs-18"> Federal Republic of Nigeria. </h5>
          </div>
        </div>
      </div>
      <div>
        <ul class="list-unstyled">
<!--          <li class="mb-1"><a href="" class="text-decoration-none"> Terms of use </a></li>-->
<!--          <li class="mb-1"><a href="" class="text-decoration-none"> Legals </a></li>-->
          <!-- <li class="mb-1 mt-2 fs-14"><span class="iconify"
                                            data-icon="fluent:database-person-24-regular"></span> Managed by the NIMC database unit
          </li> -->
        </ul>
      </div>
      <div>
        <div class="d-flex align-items-center my-2">
          <span class="iconify" data-icon="mdi:email-outline"> </span>
          <div class="px-3"> For Help: <a href="mailto: {{nimcEmail}}"> {{nimcEmail}}</a></div>
        </div>
        <div class="d-flex align-items-center my-2">
          <span class="iconify" data-icon="material-symbols:location-on-rounded"></span>
          <div class="px-3"> National Identity Management Commission <br>
            11 Sokode Crescent, Off Dalaba Street Zone 5, Wuse. <br>
            P. M. B. 18, Garki, Abuja</div>
        </div>
        <div class="d-flex align-items-center my-2">
          <span class="iconify" data-icon="ic:baseline-call"> </span>
          <div class="px-3"> <a href="tel: {{nimcTel}}"> {{nimcTel}} </a> </div>

        </div>
      </div>
    </div>
  </div>
</footer>
