import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {Constant} from "../models/Constant";


@Injectable({
    providedIn: 'root'
})
export class MultiFactorAuthenticationGuard implements CanActivate {

  hasOtp: boolean;
    constructor(private router: Router) {
        if (sessionStorage.getItem(Constant.HAS_OTP)) {
            this.hasOtp = JSON.parse(<string>sessionStorage.getItem(Constant.HAS_OTP)) || '';
        }
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (sessionStorage.getItem(Constant.HAS_OTP)) {
            this.hasOtp = JSON.parse(<string>sessionStorage.getItem(Constant.HAS_OTP)) || '';
        }
        if (this.hasOtp) {
            return true;
        }
        return  this.router.navigate(['2fa', 'fingerprint'], {state: {redirect: route.routeConfig.path}});
    }

}
