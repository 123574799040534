<div>
  <div style="display: flex; flex-direction: column; align-items: center;">
    <div class="connector" [ngClass]="fpConnected? 'active':'inactive'">
      <i *ngIf="fpConnected">
        <i class="iconify fs-2" data-icon="majesticons:scan-fingerprint" style="color: green"></i>
      </i>
      <i *ngIf="!fpConnected && pluginDetected">
        <i class="iconify fs-2" data-icon="material-symbols-light:fingerprint-off-sharp" style="color: rgba(255,0,0,0.49)"></i>
      </i>

      <i *ngIf="!fpConnected && !pluginDetected">
        <i class="iconify fs-2" data-icon="material-symbols-light:power-plug-off-outline"
           style="color: rgba(255,0,0,0.49)"></i>
      </i>
    </div>
    <div *ngIf="fpConnected" class="text-success mt-3 fs-12 fw-semibold">
      Biometric Scanner Connected
    </div>
    <div *ngIf="!fpConnected && pluginDetected" class="text-danger mt-3 fs-12 fw-semibold">
      Fingerprint Scanner Not Connected
    </div>

    <div *ngIf="!fpConnected && !pluginDetected" class="text-danger mt-3 fs-13 fw-semibold text-center">
      No Device Plugin is detected. Start Plugin if installed <br/>or
      <a (click)="goToPlugin()" class="text-link pointer">Click Here</a> to Download
    </div>

    <div *ngIf="false" class="mt-5 col-12 col-md-8 card"
         style="border: 1.043px solid #0052CC; background: rgba(158, 164, 189, 0.05);   ">
      <div class="card-body">
        <!-- Dropdown 1 -->
        <div class="dropdown">
                 <span class="dropdown-toggle" role="button" id="dropdownMenuButton1"
                       data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false"
                       aria-controls="collapseOne">
                     Setting Up Scanner
                 </span>
          <div class="collapse" id="collapseOne">
            <div class="card card-body">
              Content for Dropdown 1
            </div>
          </div>

        </div>
        <hr>
        <!-- Dropdown 2 -->
        <div class="dropdown">
                    <span class="dropdown-toggle" role="button" id="dropdownMenuButton2"
                          data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false"
                          aria-controls="collapseTwo">
                                    Contact Admin

                    </span>

          <div class="collapse" id="collapseTwo">

            <div class="card card-body">
              Content for Dropdown 2
            </div>
          </div>
        </div>
        <hr>
        <!-- Dropdown 3 -->
        <div class="dropdown">
                    <span class="dropdown-toggle" role="button" id="dropdownMenuButton3"
                          data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false"
                          aria-controls="collapseThree">
                                    Locked Account
                    </span>
          <div class="collapse" id="collapseThree">

            <div class="card card-body">
              Content for Dropdown 3
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
